<template>
  <div class="buy-detaile">
    <common-header title="购买详情" />
    <ul class="tab-list">
      <li
        @click="handleChange(item)"
        :class="{
          'tab-list-item': 'tab-list-item',
          'active-tab-list-item':
            currentId === item.id ? 'active-tab-list-item' : '',
        }"
        v-for="item in tableList"
        :key="item.id"
      >
        {{ item.name }}
      </li>
    </ul>
    <div class="cumulative-card">
      <van-row style="width: 100%" type="flex" justify="space-between">
        <van-col>
          <p class="title">购买累计</p>
          <p class="amount">{{ amountSum }} FIL</p>
        </van-col>
        <van-col>
          <p class="title">赎回累计</p>
          <p class="amount">{{ depositAmountSum }} FIL</p>
        </van-col>
      </van-row>
      <p class="date">{{ nowDate }}</p>
    </div>
    <div class="account-table">
      <h4>购买记录</h4>
      <van-list
        class="account-list"
        :finished="finished"
        finished-text="没有更多了"
      >
        <div class="table-title">
          <p class="time">时间</p>
          <p class="comput-power">FIL</p>
          <p class="type">状态</p>
          <p class="option">操作</p>
        </div>
        <div class="table-title-item" v-for="item in list" :key="item._id">
          <p class="time">{{ item.createdAt }}</p>
          <p class="comput-power">{{ item.usdtAmount }}</p>
          <p class="type">{{ status["" + item.usdtStatus] }}</p>
          <van-button
            @click="handleRedemption(item)"
            type="primary"
            class="redeem-btn"
            :disabled="!item.usdtStatus"
            >{{ item.usdtStatus === 0 ? "已赎回" : "赎回" }}</van-button
          >
        </div>
      </van-list>
    </div>
    <div class="account-table">
      <h4>赎回记录</h4>
      <van-list
        class="account-list"
        :finished="finished"
        finished-text="没有更多了"
      >
        <div class="table-title">
          <p class="time deposit-time">时间</p>
          <p class="comput-power deposit-coin">FIL</p>
          <p class="type deposit-status">状态</p>
        </div>
        <div
          class="table-title-item"
          v-for="item in depositList"
          :key="item._id"
        >
          <p class="time deposit-time">{{ item.updatedAt }}</p>
          <p style="color: #019969" class="comput-power deposit-coin">
            {{ item.usdtAmount }}
          </p>
          <p class="type deposit-status">成功</p>
        </div>
      </van-list>
    </div>
    <verify-code
      :visiable="visiable"
      @close="handleClose"
      @click="handleSubmit"
      @refersh="getVerifyCodeImg"
      v-model.trim="verifyCode"
      :verifyImg="verifyCodeImg"
    />
  </div>
</template>

<script>
import CommonHeader from "../../components/CommonHeader.vue";
import { myMinePlay, redemption, redemptionRecord } from "../../server";
import * as timeago from "timeago.js";
import VerifyCode from "../../components/VerifyCode.vue";
import _ from "lodash";
import { getVerifyCode } from "../../server/index";
import dayjs from "dayjs";
export default {
  name: "BuyDetail",
  components: { CommonHeader, VerifyCode },
  data() {
    return {
      verifyCode: "",
      visiable: false,
      verifyCodeImg: "",
      tableList: [
        { id: 1, name: "个人基金" },
        { id: 2, name: "社区基金" },
      ],
      currentId: 1,
      finished: true,
      current: 1,
      amountSum: 0,
      list: [],
      status: {
        "-1": "正在上链",
        1: "成功",
        0: "赎回",
      },

      currentRedemptionId: "",
      depositAmountSum: 0,
      depositList: [],
    };
  },
  computed: {
    nowDate() {
      return dayjs(Date.now()).format("YYYY-MM-DD");
    },
  },
  methods: {
    async handleSubmit() {
      if (!this.verifyCode) return this.$toast("请输入验证码");
      await redemption(
        this.getAddress,
        this.currentRedemptionId,
        this.verifyCode,
        this.currentId
      );
      this.visiable = false;
      await this.getMinerPlay();
      await this.getDepositRecord();
    },
    handleClose() {
      this.visiable = false;
    },
    getVerifyCodeImg: _.throttle(async function () {
      this.verifyCodeImg = await getVerifyCode(this.getAddress, 5);
    }, 2000),
    async handleRedemption(item) {
      this.currentRedemptionId = item._id;
      this.verifyCode = "";
      await this.getVerifyCodeImg();
      this.visiable = true;
    },
    async handleChange(item) {
      this.currentId = item.id;
      await this.getMinerPlay();
      await this.getDepositRecord();
    },
    async getMinerPlay() {
      const data = await myMinePlay(
        this.getAddress,
        this.currentId,
        this.current
      );
      this.amountSum = data.amountSum;
      this.list = data.list.map((item) => {
        return {
          ...item,
          createdAt: timeago.format(item.createdAt, "zh_CN"),
        };
      });
    },
    async getDepositRecord() {
      const data = await redemptionRecord(this.getAddress, this.currentId);
      this.depositAmountSum = data.amountSum;
      this.depositList = data.list.map((v) => {
        return {
          ...v,
          updatedAt: timeago.format(v.updatedAt, "zh_CN"),
        };
      });
    },
  },
  async created() {
    await this.getDepositRecord();
    await this.getMinerPlay();
    await this.getVerifyCodeImg();
  },
};
</script>

<style lang="less" scoped>
.buy-detaile {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  .tab-list {
    flex-shrink: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 343px;
    height: 50px;
    background: #161f2c;
    box-shadow: 0px 0px 10px rgba(52, 52, 52, 0.08);
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 15px;
    overflow-x: scroll;

    .tab-list-item {
      width: 153px;
      height: 40px;
      opacity: 1;
      border-radius: 10px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
      color: #9aaabf;
    }

    .active-tab-list-item {
      color: #ffffff;
      background: linear-gradient(90deg, #05c68b 0%, #019d6b 100%);
      box-shadow: 0px 3px 6px rgba(1, 153, 105, 0.25);
    }
  }
  .cumulative-card {
    flex-shrink: 0;
    width: 345px;
    height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 18px;
    margin-bottom: 15px;
    justify-content: space-around;
    background-image: url("../../assets/images/bg.png");
    background-size: cover;
    .title {
      text-align: center;
      font-size: 14px;
      font-family: "PingFang SC";
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 10px;
    }
    .amount {
      font-size: 23px;
      font-family: "DIN";
      font-weight: bold;
      color: #ffffff;
    }
    .date {
      font-size: 12px;
      font-family: "PingFang SC";
      font-weight: 400;
      color: #e9ecf0;
    }
    .user-level {
      align-self: flex-end;
      font-size: 14px;
      font-family: "PingFang SC";
      font-weight: 500;
      color: #ffffff;
    }
  }
  .account-table {
    flex-shrink: 0;
    width: 345px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    h4 {
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 15px;
      font-size: 16px;
      font-family: "PingFang SC";
      font-weight: bold;
      color: #e9ecf0;
    }

    .account-list {
      margin-bottom: 30px;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 15px;
      width: 345px;
      height: 306px;
      background: #161f2c;
      opacity: 1;
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;

      overflow-y: scroll;

      .table-title,
      .table-title-item {
        flex-shrink: 0;
        width: 100%;
        height: 37px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #263243;
        font-size: 12px;
        font-family: "PingFang SC";
        font-weight: 400;
        color: #c1c1c1;

        .time {
          width: 78px;
        }

        .comput-power {
          width: 95px;
        }

        .type {
          width: 86px;
        }

        .option {
          text-align: center;
          flex-grow: 1;
        }

        .redeem-btn {
          width: 56px;
          height: 23px;
          background: linear-gradient(180deg, #05c68b 0%, #019d6b 100%);
          border-radius: 14px;
          font-size: 12px;
          font-family: "PingFang SC";
          font-weight: 400;
          line-height: 23px;
          text-align: center;
          color: #ffffff;
          white-space: nowrap;
        }
        .deposit-time {
          width: 145px;
        }
        .deposit-coin {
          width: 146px;
        }
        .deposit-status {
          flex-grow: 1;
          text-align: right;
        }
      }

      .table-title-item {
        .type {
          color: #5399fd;
        }

        .time,
        .comput-power {
          color: #e9ecf0;
        }
      }
    }
  }
}
</style>
